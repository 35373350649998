/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "./theme/variables.scss";

// ICONS
.icon {
    &::before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        height: 40px;
        width: 40px;
        background-image: url("./assets/icon/socotec-sprite-icones.png");
        background-size: 700%;
    }
}
.icon-menu {
    &::before {
        background-position: -14px -17px;
    }

    &.dark::before {
        background-position: -65px -17px;
    }
}
.icon-connect {
    &::before {
        background-position: -111px -17px;
    }
    &.dark::before {
        background-position: -157px -17px;
    }
}
.icon-disconnect::before {
    background-position: -175px -307px;
}
.icon-tel {
    &::before {
        background-position: -200px -17px;
    }
    &.dark::before {
        background-position: -232px -17px;
    }
}
.icon-plus {
    &::before {
        background-position: -14px -70px;
    }
    &.dark::before {
        background-position: -64px -70px;
    }
}
.icon-question::before {
    background-position: -111px -70px;
}
.icon-rapport {
    &::before {
        background-position: -159px -70px;
    }
    &.dark::before {
        background-position: -205px -70px;
    }
}
.icon-detail {
    &::before {
        background-size: 600%;
        background-position: -14px -113px;
    }
    &.dark::before {
        background-position: -57px -113px;
    }
}
.icon-list {
    &::before {
        background-position: -114px -133px;
    }
    &.dark::before {
        background-position: -159px -133px;
    }
}
.icon-check {
    &::before {
        background-position: -18px -192px;
    }
    &.dark::before {
        background-position: -64px -192px;
    }
}
.icon-reload {
    &::before {
        background-position: -113px -193px;
    }
    &.dark::before {
        background-position: -159px -193px;
    }
}
.icon-close {
    &::before {
        background-position: -205px -192px;
    }
    &.dark::before {
        background-position: -205px -136px;
    }
}
.icon-leftarrow::before {
    background-position: -24px -258px;
}
.icon-rightarrow::before {
    background-position: -64px -248px;
}
.icon-filtersmall::before {
    background-position: -112px -248px;
}
.icon-filterbig {
    &::before {
        background-position: -158px -248px;
    }
    &.dark::before {
        background-position: -223px -307px;
    }
}
.icon-search::before {
    background-position: -209px -250px;
}
.icon-pdf::before {
    background-position: -15px -306px;
}
.icon-socotec::before {
    background-position: -70px -305px;
}
.icon-qrcode::before {
    background-position: -126px -306px;
}
.icon-swipe::before {
    background-position: -22px -355px;
}
.icon-photo::before {
    background-position: -61px -308px;
    background-size: 608%;
}
.icon-trash::before {
    background-position: -106px -307px;
    background-size: 608%;
}

// APP
@font-face {
	font-family: 'Roboto';
	src: url('./assets/fonts/Roboto-Regular.eot');
	src: url('./assets/fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
		 url('./assets/fonts/Roboto-Regular.woff') format('woff'),
		 url('./assets/fonts/Roboto-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

html, body, p, ul, li, h1, h2, h3, h4 {
    margin: 0;
    padding: 0;
    font-family: 'Roboto';
    font-size: 16px;
}
body {
    ion-app.app-root {
        font-family: 'Roboto';
        font-size: 16px;
        
        .spinner {
            text-align: center;
            padding-top: $menu-bar-height-search + 10px;
            padding-bottom: 10px;
        }
        ion-toast {
            .toast-wrapper {
                .toast-message {
                    font-size: 15px;
                    text-align: center;
                }
            }
        }
        ion-picker-cmp {
            .picker-button {
                font-size: 1rem;
                color: $bleu3;
            }
            .picker-opt {
                height: 2.6rem;
            }
        }
    }
}

ion-menu ion-content {
    --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
    --padding-start: 8px;
    --padding-end: 8px;
    --padding-top: 20px;
    --padding-bottom: 20px;
}

ion-menu.md ion-list {
    padding: 20px 0;
    padding-top: 0px;
}

ion-menu.md ion-note {
margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header {
font-size: 22px;
font-weight: 600;

min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
font-size: 16px;

margin-bottom: 18px;

color: #757575;

min-height: 26px;
}

ion-menu.md ion-item {
--padding-start: 10px;
--padding-end: 10px;
border-radius: 4px;
}

ion-menu.md ion-item.selected {
--background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
color: #616e7e;
}

ion-menu.md ion-item ion-label {
font-weight: 500;
}

ion-menu.ios ion-content {
--padding-bottom: 20px;
}

ion-menu.ios ion-list {
padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
line-height: 24px;
margin-bottom: 20px;
}

ion-menu.ios ion-item {
--padding-start: 16px;
--padding-end: 16px;
--min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
font-size: 24px;
color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
padding-left: 16px;
padding-right: 16px;
}

ion-menu.ios ion-note {
margin-bottom: 8px;
}

ion-note {
display: inline-block;
font-size: 16px;

color: var(--ion-color-medium-shade);
}

ion-item.selected {
--color: var(--ion-color-primary);
}

// HOME PAGE
page-home {
    background: url("./assets/images/main-home.jpg") center ($menu-bar-height + 16px) no-repeat;
    background-size: 80%;
    background-color: $bleu2;
    
    ion-content {
        --background: none;
        main {
            display: block;
            position: absolute;
            bottom: 0px;
            color: $blanc;
            padding-bottom: 10px;
            width: 100%;

            nav {
                display: none;
            }
        }

        h1, p {
            text-align: center;
        }

        h1 {
            font-weight: 400;
            font-size: 24px;
            line-height: 34px;
            margin-bottom: 16px;
        }

        p {
            padding: 0 24px;
            line-height: 24px;
            font-size: 14px;
            
            &.desktop {
                display: none;
                margin-top: 25px;
            }
        }
    }
    ion-footer {
        ion-list.spinner {
            padding: 0 !important;
            height: 159px;
    
            ion-spinner {
                height: 100%;
            }
        }
        ion-list {
            background-color: $noir !important;
            color: $blanc;
            margin: 0;
            padding-top: 0px !important;
            padding-bottom: 0px !important;

            ion-item {
                --background: transparent;
                &:first-child {
                    border-top: none;
                }
                &:last-child {
                    border-bottom: none;
                }
            }
    
            button {
                text-decoration: none;
                background-color: transparent;
                color: $blanc;
                border-top: 1px solid $gris-fonce;
                display: block;
                padding: 6px 5%;
                font-size: 18px;
                min-height: auto;
                width: 100%;
                text-align: left;
                
                &.disabled {
                    color: $disabled-color;
                }
                .icon-qrcode + span {
                    color: $bleu2;
                }
                span {
                    margin-left: 10px;
                    vertical-align: middle;
                }
            }
        }
    }
}

// HEADER
component-header {    
    :host {
        display: block;
        height: $menu-bar-height;
        position: relative;
        z-index: 20;
    }
    
    ion-header {
        .toolbar-ios {
            padding-top:0px !important;
            transform: translateY(-8px);
        }
        ion-toolbar {
            --background: transparent;
            min-height: $menu-bar-height;
            color: $blanc;
            background-color: #f8f8f8;
    
            &.bleu {
                background-color: $bleu3;
            }
            &.bleu-clair {
                background-color: $bleu-clair;
            }
            &.blanc {
                color: $noir;
            }
            &.noir {
                background-color: $noir;
            }
            &.orange {
                background-color: $orange;
            }
            ion-menu-toggle {
                height: auto;
    
                & ~ .toolbar-content {
                    position: relative;
                    height: 40px;
    
                    .title {
                        padding-left: 0px;
                        float: left;
                        line-height: initial;
                        height: 40px;
                    }
                }
                ion-icon {
                    width: 48px;
                    height: 40px;
                }
            }
            ion-button[backbtn] {
                background-color: transparent;
                height: 32px;
                box-shadow: none;
                float: left;
                
                &:hover {
                    background-color: transparent;
                }
                + ion-title {
                    margin-left: 50px;
                    
                    &.title-md {
                        margin-top: 3px;
                    }
                }
            }
            .title {
                vertical-align: middle;
                line-height: 40px;
                transition: color .4s;
                padding-left: 24px;
                font-size: 18px;
            }
            .left-icon {
                float: left;
                padding: 0 0 0 5%;
            }
            .right-icon {
                float: right;
                padding-right: 8%;
                height: 45px;
            }
        }
    }
    .nomElement {
        background-color: $gris-fonce;
        color: $blanc;
        height: 29px;
        padding: 0 35px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        
        h3 {
            display: inline;
            line-height: 29px;
        }
    }
}

// MENU
ion-menu {
    component-menu {
        height: 100%;
    }
    ion-toolbar {
        padding: 0 5%;
        color: $blanc;
        min-height: 52px;
        --background: rgb(66, 66, 66);
        
        .icon-menu {
            margin-right: 12px;
        }
        .right-icon {
            float: right;
        }
        ion-title {
            padding: 6px 5%;
            height: auto;
            vertical-align: middle;
            line-height: 40px;
            transition: color .4s;
            float: left;
        }
        ion-menu-toggle {
            background-color: transparent;
            min-height: 40px;
            
           ion-button {
                width: 65px;
                margin: 6px 0;
                float: right;
                padding: 0;

                ion-icon {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    ion-content {
        --background: rgb(51, 51, 51);
        --padding-top: 0px !important;
        ion-list {
            background-color: transparent !important;  
            ion-item {
                --background: transparent;
                background-color: transparent;
                border-bottom: 1px solid $gris-fonce;
                color: $blanc;
                transition: left .4s;
                font-size: 20px;
                min-height: initial;
                
                &::part(native) {
                    height: 66px;
                }
                &.title {
                    &::part(native) {
                        height: 20px;
                    }
                    span {
                        font-size: 16px;
                        padding-left: 0px;
                    }
                }
                &.sub-item {
                    padding-left: 46px;
                }
                &.disabled {
                    color: $disabled-color;
                }
                &.active {
                    background-color: darken($noir, 5%);
                }
                &.spinner {
                    padding-top: 11px;
                }
                &:first-child {
                    border-top: none;
                }
                &:last-child {
                    border-bottom: 1px solid $gris-fonce;
                }
                background: none;
                border-bottom: none;
                
                .icon-qrcode + span {
                    color: $bleu2;
                }
                span {
                    padding-left: 10px;
                    vertical-align: middle;
                    font-size: 20px;
                }
                button {
                    background-color: transparent;
                }
            }
        }
        nav {
            ion-item {
                display: none;
            }
        }
    }

    &.show-menu {
        ion-toolbar {
            background-color: $gris-fonce;
            color: $blanc;
            
            .toolbar-background {
                background-color: transparent;
            }
        }
    }
}

// SAVOIR PLUS
page-savoir-plus {
    component-header {
        ion-toolbar {
            background-color: $gris-clair;
            
            .toolbar-background {
                background-color: transparent;
                border-bottom: none;
            }
            .toolbar-content {
                ion-title {
                    float: left;
                }
            }
        }
    }
    ion-content {
        main {
            display: block;
            color: #000;
            background-color: $blanc;
            padding: 24px 24px 24px;
            
            p {
                margin-bottom: 16px;
            }
            img {
                display: block;
                width: 60%;
                max-width: 370px;
                margin: 16px auto;
                
                &.numero {
                    max-width: 180px;
                }
            }
            h2 {
                font-size: 16px;
                margin-bottom: 32px;;
            }
            ul {
                list-style-type: "-";
                list-style-position: inside;
                margin-bottom: 32px;

                li {
                    margin-bottom: 16px;
                    span {
                        margin-left: 16px;
                    }
                }
            }
            a {
                text-decoration: none;
                color: $noir;
                font-weight: 700;
            }
        }
    }
}

// CONNEXION
page-connexion {
    component-header {
        ion-toolbar {
            background-color: $gris-clair;

            .toolbar-background {
                background-color: transparent;
                border-bottom: none;
            }
        }
    }
    ion-content {
        main {
            display: block;
            padding-bottom: 60px;
            background-color: $blanc;
            min-height: 100%;

            header {
                height: 60px;
                display: block;
                position: relative;

                .fixed-container {
                    display: flex;
                    align-items: center;
                    padding: 0 24px;
                    height: 60px;
                    width: 100%;
                    position: fixed;
                    z-index: 4;
                    background-color: $noir;
                    color: $blanc;
                }
            }

            h1 {
                text-transform: uppercase;
                font-size: 18px;
                font-weight: 400;

                span.spinner {
                    position: absolute;
                    top: 15px;
                    right: 24px;
                }
            }

            form {
                padding-top: 24px;
                padding-bottom: 50px;
                background-color: $blanc;
            }

            .form-field {
                padding: 0 24px;
                margin-bottom: 16px;
            }
            .spinner {
                padding: 0 0 20px !important;
            }
            .profil {
                padding: 0;
                color: black;
                ion-label {
                    display: block;
                    text-align: center;
                }
                .not-selected {
                    color: $gris-clair;
                }
                ion-toggle {
                    &.toggle-ios {
                        width: auto;
                        .toggle-icon {
                            width: 51px;
                            height: 32px;
                            margin: auto;
                        }
                    }
                    &.toggle-md {
                        width: auto;
                        .toggle-icon {
                            width: 36px;
                            height: 14px;
                            margin: auto;
                        }
                    }
                    --background: #005499;
                    --background-checked: #005499;

                    --handle-background: white;
                    --handle-background-checked: white;
                }
                button {
                    border-top: none;
                }
            }
            .login, .password {
                color: black;
                label {
                    display: block;
                    margin-bottom: 8px;
                }
                span {
                    position: absolute;
                    bottom: 10px;
                    padding-left: 16px;
                    color: $noir;
                }
                input {
                    box-sizing: border-box;
                    width: 100%;
                    height: 40px;
                    max-width: 400px;
                    padding: 0 16px;
                    border: 1px solid $gris-clair;
                    border-radius: 40px;
                    background-color: transparent;
                }
                .error {
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                    color: red;
                    margin-top: 5px;
                }
            }
            .login {
                position: relative;
                .is-collaborateur {
                    padding-left: 9.2ch;
                }
            }
            .password {
                margin-bottom: 32px;
            }
            .remember {
                label {
                    vertical-align: middle;
                    color: black;
                }

                label.icon {
                    width: 40px;
                    height: 40px;
                    cursor: pointer;
                    border: 1px solid $gris-clair;
                    display: inline-block;
                    margin-right: 10px;

                    &::before {
                        visibility: hidden;
                    }
                }
                input {
                    display: none;

                    &:checked + .icon::before {
                        visibility: visible; 
                    }
                }
            }

            p {
                color: $gris-clair;
                font-style: italic;
            }

            a {
                color: $noir;
                text-decoration: underline;
                cursor: pointer;
                .icon {
                    margin-right: 10px;
                }
            }
            button {
                display: block;
                position: fixed;
                bottom: 0;
                left: 0;
                width: 100%;
                background-color: $blanc;
                border-top: 1px solid $gris-clair;
                color: $noir;
                margin: 0;
                padding: 6px 5%;
                text-align: left;
                font-size: 18px;
                &[disabled] {
                    opacity: 1;
                    i, span {
                        opacity: .4;
                    }
                }
                span {
                    margin-left: 10px;
                    vertical-align: middle;
                }
                &.showFooter {
                    visibility: hidden;
                }
            }
        }
    }
}

// MOT DE PASSE OUBLIE
page-mot-de-passe-oublie {
    component-header {
        ion-toolbar {
            background-color: $gris-clair;

            .toolbar-background {
                background-color: transparent;
                border-bottom: none;
            }
        }
    }
    ion-content {
         main {
            display: block;
            padding-bottom: 160px;
            background-color: $blanc;
            min-height: 100%;
             
            header {
                height: 60px;
                display: block;
                position: relative;

                .fixed-container {
                    display: flex;
                    align-items: center;
                    padding: 0 24px;
                    height: 60px;
                    width: 100%;
                    position: fixed;
                    z-index: 4;
                    background-color: $noir;
                    color: $blanc;
                }
            }

            h1 {
                text-transform: uppercase;
                font-size: 18px;
                font-weight: 400;
            }

            form {
                padding-top: 24px;
                background-color: $blanc;
            }

            .form-field {
                padding: 0 24px;
                margin-bottom: 16px;
            }
            .spinner {
                padding: 0 0 20px !important;
                text-align: center;
                ion-spinner {
                    color: $noir;
                }
            }
            .login, .password {
                color: black;
                label {
                    display: block;
                    margin-bottom: 8px;
                }

                input {
                    box-sizing: border-box;
                    width: 100%;
                    height: 40px;
                    max-width: 400px;
                    padding: 0 16px;
                    border: 1px solid $gris-clair;
                    border-radius: 40px;
                    background-color: transparent;
                }
                .error {
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                    color: red;
                    margin-top: 5px;
                }
            }

            .password {
                margin-bottom: 32px;
            }

            .remember {
                label {
                    vertical-align: middle;
                    color: black;
                }

                label.icon {
                    width: 40px;
                    height: 40px;
                    cursor: pointer;
                    border: 1px solid $gris-clair;
                    display: inline-block;
                    margin-right: 10px;

                    &::before {
                        visibility: hidden;
                    }
                }
                input {
                    display: none;
                    &:checked + .icon::before {
                        visibility: visible; 
                    }
                }
            }

            p {
                color: $gris-clair;
                font-style: italic;
            }

            a {
                color: $noir;
                
                .icon {
                    margin-right: 10px;
                }
            }
            button {
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                background-color: transparent;
                border-top: 1px solid $gris-clair;
                color: $noir;
                margin: 0;
                padding: 6px 5%;
                text-align: left;
                font-size: 18px;
                &[disabled] {
                    opacity: 1;
                    i, span {
                        opacity: .4;
                    }
                }
                span {
                    margin-left: 10px;
                    vertical-align: middle;
                }
            }
        }
    }
}

// RAPPORT
page-rapport {
    component-header {
        ion-toolbar {
            background-color: $bleu3;
            
            .toolbar-background {
                background-color: transparent;
                border-bottom: none;
            }
        }
    }
    ion-content {
        .spinner {
            padding: 20px 0 20px !important;
            text-align: center;
            ion-spinner {
                color: $noir;
            }
        }
        main {
            display: block;
            background-color: $blanc;
            min-height: 100%;
            
            header {
                height: 60px;
                display: block;
                position: relative;

                button {
                    justify-content: space-between;
                    align-items: center;
                    height: 60px;
                    width: 100%;
                    padding: 0 20px 0 20px;
                    position: fixed;
                    z-index: 4;
                    background-color: $bleu1;
                    color: $blanc;
                    min-height: initial;
                    display: flex;
                    
                    
                }
                &.highlight button {
                    background-color: $orange;
                }
            }
            ion-list {
                background-color: transparent !important;
                ion-item {
                    --background: transparent;
                    background-color: transparent;
                    padding: 0;
                    color: $gris-clair;
                    min-height: initial;
                    
                    &.highlight::before {
                        content: "";
                        display: block;
                        height: 100%;
                        width: 4px;
                        background-color: #FF6A00;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                    h3 {
                        font-size: 14px;
                    }
                    p {
                        color: $noir;
                        font-size: 16px;
                    }
                    .highlight {
                        color: $orange;
                    }
                    ion-note {
                        font-size: 16px;
                        padding: 0;
                    }
                }
            }
            .no-rapport {
                background-color: transparent !important;
                --background: transparent;
                padding: 0;
                color: $gris-clair;
                min-height: initial;
                
                .item-inner {
                    padding: 6px 5% 6px 10%;
                    ion-label {
                        margin: 0;

                        h3 {
                            font-size: 14px;
                        }
                        p {
                            color: $noir;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}
ion-alert[role="alertdialog"] {
    .alert-wrapper {
        max-width: 90%;
        width: 100%;
    }
    .alert-head {
        padding: 0;
        .alert-title {
            background-color: $gris-fonce;
            text-align: center;
            text-transform: uppercase;
            color: $blanc;
            margin: 0;
            padding: 12px 24px;
        }
        .alert-sub-title {
            font-family: 'Roboto';
            font-size: 14px;
            text-align: center;
            margin: 0;
            padding: 12px 24px;
            a {
                display: inline-block;
                height: 58px;
                width: 168px;
                overflow: hidden;
                background: url("./assets/images/icones-app.png") no-repeat;
                background-size: 200%;
                text-indent: -3000%;
                @media (max-width:767px) {
                    height: 46px;
                    width: 142px;
                } 
                &.app-store {
                    background-position: 0 0;
                }
                &.google-play {
                    background-position: 100% 0;
                }
            }
        }
    }
    .alert-radio-group {
        background-color: $blanc;
        .alert-radio-label {
            color: $noir;
        }
        .alert-radio-inner {
            background-color: $bleu3;
        }
    }
    .alert-button-group {
        background-color: $blanc;
        justify-content: center;
        padding: 8px;
        border-top: 1px solid $gris-clair;
        .alert-button {
            font-size: 18px;
            text-align: center;
            text-transform: inherit;
            color: $bleu2;
            margin: 0;
            border-top: none;
            display: inline-block;
        }
        &:empty {
            padding: 0;
            border-top: none;
        }
    }
    .alert-message {
        background-color: $blanc;
        color: $noir;
    }
}

// ELEMENT VERIFIE
page-element-verifie {
    component-header {
        ion-toolbar {
            background-color: $bleu3;
            
            .toolbar-background {
                background-color: transparent;
                border-bottom: none;
            }
        }
    }
    ion-content {
        .spinner {
            padding: 20px 0 20px !important;
            text-align: center;
            ion-spinner {
                color: $noir;
            }
        }
        main {
            display: block;
            background-color: $blanc;
            min-height: 100%;
             
            header {
                height: 60px;
                display: block;
                position: relative;

                button {
                    justify-content: space-between;
                    align-items: center;
                    height: 60px;
                    width: 100%;
                    padding: 0 20px 0 20px;
                    position: fixed;
                    z-index: 4;
                    background-color: $bleu1;
                    color: $blanc;
                    min-height: initial;
                    display: flex;
                }
                &.highlight button {
                    background-color: $orange;
                }
            }
            ion-list {
                background-color: transparent !important;
                ion-item {
                    --background: transparent;
                    background-color: transparent;
                    padding: 0;
                    color: $gris-clair;
                    min-height: initial;
                    
                    &.highlight::before {
                        content: "";
                        display: block;
                        height: 100%;
                        width: 4px;
                        background-color: #FF6A00;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                    h3 {
                        font-size: 14px;
                        
                        &.highlight {
                            color: $orange;
                        }
                    }
                    p {
                        color: $noir;
                        font-size: 16px;
                        
                        &.highlight {
                            color: $orange;
                        }
                    }
                    .highlight {
                        color: $orange;
                    }
                    ion-note {
                        font-size: 16px;
                        padding: 0;
                    }
                }
            }
            .no-element {
                background-color: transparent !important;
                --background: transparent;
                padding: 0 24px;
                font-size: 16px;
                min-height: 4.4rem;
                color: $gris-clair;
                
                .item-inner {
                    padding: 0;
                }
            }
            nav {
                position: fixed;
                bottom: 0;
                width: 100%;

                button {
                    display: block;
                    padding: 6px 5%;
                    background-color: $noir;
                    color: $blanc;
                    text-decoration: none;
                    font-size: 16px;
                    min-height: initial;
                    width: 100%;
                }
            }
        }
    }
}

// LISTE OBSERVATIONS RAPPORT
page-liste-observations-rapport {
    component-header {
        ion-toolbar {
            background-color: $bleu3;

            .toolbar-background {
                background-color: transparent;
                border-bottom: none;
            }
        }
    }
    ion-content {
        main {
            display: block;
            background-color: $blanc;
            min-height: 100%;

            header {
                height: 60px;
                display: block;
                position: relative;

                .fixed-container {
                    z-index: 4;
                    height: 60px;
                    width: 100%;
                    position: fixed;
                    background-color: $noir;
                    color: $blanc;
                }
            }
            ion-list {
                background-color: transparent !important;
                ion-item {
                    --background: transparent;
                    background-color: transparent;
                    padding: 0;
                    color: $gris-clair;
                    min-height: initial;
                    border-bottom: 0.55px solid #c8c7cc;
                    -webkit-box-align: initial;
                    -webkit-align-items: initial;
                    align-items: initial;

                    &.highlight::before {
                        content: "";
                        display: block;
                        height: 100%;
                        width: 4px;
                        background-color: #FF6A00;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                    h3 {
                        font-size: 14px;
                        font-weight: bold;
                        line-height: 22px;
                    }
                    p {
                        color: $noir;
                        font-size: 16px;
                    }
                    .bold {
                        font-weight: bold;
                    }
                    border-bottom: none !important;
                    ion-note {
                        font-size: 12px;
                        padding: 5px 0;
                        margin: 5px 5px 0px 0px;
                        background-color: $gris-clair;
                        color: $blanc;
                        border-radius: 50%;
                        width: 22px;
                        height: 22px;
                        text-align: center;

                        &.highlight {
                            background-color: $orange;
                            color: $blanc;
                        }
                    }
                    img {
                        position: relative;
                        top: 50%;
                        transform: translateY(-50%);
                        margin: 0;
                    }
                }
            }
            .no-element {
                background-color: transparent !important;
                --background: transparent;
                padding: 0 24px;
                font-size: 16px;
                min-height: 4.4rem;
                color: $gris-clair;
            }
        }
        .spinner {
            padding: 20px 0 20px !important;
            text-align: center;
            ion-spinner {
                color: $noir;
            }
        }
    }
}

// COMPONENT SEARCH
component-search {
    form {
        background-color: $gris-clair;
        padding: 9px 5%;
        
        input {
            box-sizing: border-box;
            width: calc(100% - 44px);
            height: 40px;
            max-width: 400px;
            padding: 0 16px;
            border-radius: 40px;
            border: none;
            color: $noir;
            background-color: white;
        }
    
        button {
            margin: 0;
            padding: 0;
            border: none;
            background: transparent;
        }
    }
}

// FILTRES
page-filtres {
    component-header {
        ion-toolbar {
            background-color: $gris-clair;

            .toolbar-background {
                background-color: transparent;
                border-bottom: none;
            }
            .toolbar-content {
                ion-title {
                    float: left;
                }
            }
        }
    }
    ion-content {
        main {
            display: block;
            background-color: $blanc;
            min-height: 100%;
            padding: 24px 0 24px;

            form {
                background-color: transparent;
                padding: 0;
            }
            ion-item {
                --background: transparent;

                ion-label {
                    font-size: 16px !important;
                    text-transform: uppercase !important;
                    color: $noir !important;
                    display: block !important;
                    margin-bottom: 20px !important;
                }
                ion-select {
                    display: block;
                    box-sizing: border-box;
                    width: 100%;
                    height: 40px;
                    max-width: 400px;
                    padding: 0 16px;
                    border: 1px solid $gris-clair;
                    border-radius: 40px;
                    font-size: 14px;
                    margin: 0;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    background-image:
                        linear-gradient(45deg, transparent 50%, $gris-clair 50%, $gris-clair 60%, transparent 60%),
                        linear-gradient(135deg, transparent 40%, $gris-clair 40%, $gris-clair 50%, transparent 50%);
                    background-position:
                        calc(100% - 22px) calc(1em + 2px),
                        calc(100% - 10px) calc(1em + 2px);
                    background-size:
                        15px 10px,
                        15px 10px;
                    background-repeat: no-repeat;
                    color: $noir;

                    &::part(text) {
                        line-height: 40px;
                        padding-right: 30px;
                    }
                    &::part(icon) {
                        display: none;
                    }
                    button {
                        border: none;
                    }
                }
                h3 {
                    line-height: 40px;
                    padding-left: 24px;
                    font-size: 18px;
                    color: $blanc;
                    text-transform: initial;
                }
                p {
                    text-transform: initial;
                    color: $noir;
                    font-size: 16px;
                    position: relative;
                    
                    label {
                        input[type="radio"] {
                            display: none;
                        }
                        span {
                            display: inline-block;
                            vertical-align: top;
                            line-height: 40px;
                        }
                        input[type="radio"]:checked + .control__indicator:after {
                            display: inline-block;
                        }
                        .control__indicator {
                            display: inline-block;
                            &:before {
                                content: "";
                                display: inline-block;
                                height: 40px;
                                width: 40px;
                                border: 1px solid $gris-fonce;
                                border-radius: 50%;
                                margin-right: 10px;
                            }
                            &:after {
                                content: "";
                                display: none;
                                vertical-align: middle;
                                height: 40px;
                                width: 40px;
                                background-image: url("./assets/icon/socotec-sprite-icones.png");
                                background-size: 650%;
                                background-position: -57px -175px;
                                position: absolute;
                                top: 0;
                                left: 0;
                            }
                        }
                    }
                }
                &.title {
                    background-color: $noir;
                    --background: $noir;
                    margin: 25px 0 15px;
                    min-height: auto;
                    ion-label {
                        margin: 6px 0;
                    }
                }
            }
            button {
                width: 100%;
                background-color: $blanc;
                border: none;
                border-top: 1px solid $gris-clair;
                border-bottom: 1px solid $gris-clair;
                font-size: 18px;
                text-align: center;
                margin-top: 32px;
            }
        }
    }
}

// DERNIERS SCANS
page-derniers-scans {
    component-header {
        ion-toolbar {
            background-color: $gris-clair;
            
            .toolbar-background {
                background-color: transparent;
                border-bottom: none;
            }
        }
    }
    ion-content {
        main {
            display: block;
            padding-bottom: 160px;
            background-color: $blanc;
            min-height: 100%;
             
            header {
                height: 60px;
                display: block;
                position: relative;

                .fixed-container {
                    display: flex;
                    align-items: center;
                    padding: 0 24px;
                    height: 60px;
                    width: 100%;
                    position: fixed;
                    z-index: 4;
                    background-color: $noir;
                    color: $blanc;
                    
                    h1 {
                        text-transform: uppercase;
                        font-size: 18px;
                        font-weight: 400;
                    }
                }
            }
            section.content {
                ion-list {
                    background-color: transparent !important;
                    ion-item {
                        color: $noir;
                        --background: transparent;
                        height: fit-content;
                        min-height: initial;
                        padding: 6px 5% 6px 10%;
                        border-bottom: 1px solid $gris-clair;
                        li {
                            h3 {
                                font-size: 14px;
                                color: $gris-clair;
                                margin-bottom: 0;
                            }
                            p {
                                font-size: 16px;
                                color: $noir;
                            }
                        }
                    }
                }
            }
        }
    }
}

// LISTE ELEMENTS VERIFIES
page-liste-elements-verifies {
    component-header {
        ion-toolbar {
            background-color: $bleu3;
            
            .toolbar-background {
                background-color: transparent;
                border-bottom: none;
            }
        }
    }
    ion-content {
         main {
            display: block;
            background-color: $blanc;
            min-height: 100%;
             
            header {
                height: 60px;
                display: block;
                position: relative;

                .fixed-container {
                    z-index: 4;
                    height: 60px;
                    width: 100%;
                    position: fixed;
                    background-color: $noir;
                    color: $blanc;
                }
            }
            ion-list {
                background-color: transparent !important;
                ion-item {
                    --background: transparent;
                    background-color: transparent;
                    padding: 0;
                    color: $gris-clair;
                    min-height: initial;
                    
                    &.highlight::before {
                        content: "";
                        display: block;
                        height: 100%;
                        width: 4px;
                        background-color: #FF6A00;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                    ul {
                        width: 90%;
                    }
                    ion-label {
                        width: 95%;
                        h3 {
                            font-size: 14px !important;
                        }
                        p {
                            color: $noir !important;
                            font-size: 16px !important;
                        }
                    }
                    .bold {
                        font-weight: bold;
                    }
                    .highlight {
                        color: $orange;
                    }
                    ion-note {
                        margin-top: 12px;
                        font-size: 16px;
                        padding: 0;
                    }
                }
            }
            .no-element {
                padding: 0 24px;
                font-size: 16px;
            }
        }
        .spinner {
            padding: 20px 0 20px !important;
            text-align: center;
            ion-spinner {
                color: $noir;
            }
        }
    }
}

// LISTE OBSERVATIONS VERIFIES
page-liste-observations-verifies {
    component-header {
        ion-toolbar {
            background-color: $bleu3;
            
            .toolbar-background {
                background-color: transparent;
                border-bottom: none;
            }
        }
    }
    ion-content {
        main {
            display: block;
            background-color: $blanc;
            min-height: 100%;
             
            header {
                height: 60px;
                display: block;
                position: relative;

                .fixed-container {
                    z-index: 4;
                    height: 60px;
                    width: 100%;
                    position: fixed;
                    background-color: $noir;
                    color: $blanc;
                }
            }
            ion-list {
                background-color: transparent !important;
                ion-item {
                    --background: transparent;
                    background-color: transparent;
                    padding: 0;
                    color: $gris-clair;
                    min-height: initial;
                    border-bottom: 0.55px solid #c8c7cc;
                    -webkit-box-align: initial;
                    -webkit-align-items: initial;
                    align-items: initial;
                    
                    &.highlight::before {
                        content: "";
                        display: block;
                        height: 100%;
                        width: 4px;
                        background-color: #FF6A00;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                    ion-label {
                        margin: 0 8px 0 5px;
                        white-space: normal;
                        display: flex !important;
                        align-items: center;
                        
                        h3 {
                            font-size: 14px;
                            font-weight: bold;
                            line-height: 22px;
                        }
                        p {
                            color: $noir;
                            font-size: 16px;
                        }
                    }
                    ion-note[item-start] {
                        font-size: 12px;
                        padding: 5px 0;
                        margin: 10px 0 5px 8px;
                        color: $gris-clair;
                        border-radius: 50%;
                        width: 22px;
                        height: 22px;
                        text-align: center;
                        
                        &.highlight {
                            color: $orange;
                        }
                    }
                    img[item-right] {
                        position: relative;
                        top: 50%;
                        transform: translateY(-50%);
                        margin: 0;
                    }
                }
            }
            .no-element {
                padding: 0 24px;
                font-size: 16px;
            }
        }
        .spinner {
            padding: 20px 0 20px !important;
            text-align: center;
            ion-spinner {
                color: $noir;
            }
        }
    }
}

// RAPORT SANS ACCES
page-rapport-sans-acces {
    component-header {
        ion-toolbar {
            background-color: $gris-clair;

            .toolbar-background {
                background-color: transparent;
                border-bottom: none;
            }
        }
    }
    ion-content {
        main {
            display: block;
            padding-bottom: 160px;
            background-color: $blanc;
            min-height: 100%;

            .content {
                padding: 24px;

                h2 {
                    font-weight: 400;
                    text-transform: uppercase;
                    margin-bottom: 8px;
                    color: $noir;
                }

                p {
                    color: $noir;
                    margin-bottom: 16px;
                }

                .date-block {
                    margin-bottom: 32px;
                    span {
                        display: block;

                        &:first-child {
                            color: $gris-clair;
                        }
                    }
                }

                .icon-socotec::before {
                    height: 90px;
                    width: 90px;
                    background-position: -147px -646px;
                    background-size: auto auto;
                }
                nav {
                    display: none;
                    margin-top: 50px;
                    text-align: center;
                    a {
                        display: inline-block;
                        height: 58px;
                        width: 168px;
                        overflow: hidden;
                        background: url("./assets/images/icones-app.png") no-repeat;
                        background-size: 200%;
                        text-indent: -3000%;
                        @media (max-width: 767px) {
                            height: 46px;
                            width: 120px;
                        }
                        &.app-store {
                            background-position: 0 0;
                        }
                        &.google-play {
                            background-position: 100% 0;
                        }
                    }
                }
            }
        }
        ion-list {
            position: absolute;
            bottom: 0;
            width: 100%;
            margin: 0;
            background-color: $noir !important;
            color: $blanc;
            padding-top: 0px !important;
            padding-bottom: 0px !important;

            ion-item {
                
                --background: transparent;
                &:first-child {
                    border-top: none;
                }
                &:last-child {
                    border-bottom: none;
                }
            }
    
            button {
                text-decoration: none;
                background-color: transparent;
                color: $blanc;
                border-top: 1px solid $gris-fonce;
                display: block;
                padding: 6px 5%;
                font-size: 18px;
                min-height: auto;
                width: 100%;
                text-align: left;
                
                &.disabled {
                    color: $disabled-color;
                }
                .icon-qrcode + span {
                    color: $bleu2;
                }
                span {
                    margin-left: 10px;
                    vertical-align: middle;
                }
            }
        }
    }
}

// DETAILS OBSERVATION
page-details-observation {
    component-header {
        ion-toolbar {
            background-color: $bleu3;

            .toolbar-background {
                background-color: transparent;
                border-bottom: none;
            }
        }
    }
    ion-content {
        main {
            display: block;
            background-color: $blanc;
            min-height: 100%;

            ion-list {
                margin-bottom: 0;
                background-color: transparent !important;
                ion-item {
                    border-bottom: 1px solid $gris-clair;
                    --background: transparent;
                    background-color: transparent;
                    padding: 0;
                    color: $gris-clair;
                    min-height: initial;
                    h3 {
                        font-size: 14px;
                    }
                    p {
                        color: $noir;
                        font-size: 16px;
                    }
                    figure {
                        margin: 0;
                        height: 100%;
                    }
                }
                ion-item.photos {
                    border-bottom: none;
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
                ion-item.button {
                    cursor: pointer;
                    border-bottom: none;
                    background-color: $bleu-clair;
                    // width: 100%;
                    // height: 100%;
                    padding: 6px 10px 6px 10%;
                    p, span {
                        font-size: 16px;
                        color: $blanc;
                    }
                    span[item-end] {
                        font-size: 20px;
                        font-weight: bold;
                    }
                }
            }
            .no-element {
                padding: 0 24px;
                font-size: 16px;
                --background: transparent;
                background-color: transparent;
                color: $noir;
            }
        }
    }
}

// SUIVI OBSERVATION
page-suivi-observation {
    component-header {
        ion-toolbar {
            background-color: $bleu3;

            .toolbar-background {
                background-color: transparent;
                border-bottom: none;
            }
        }
    }
    ion-content {
        main {
            display: block;
            background-color: $blanc;
            height: fit-content;
            min-height: 100%;
            form {
                background-color: transparent;
                padding: 0;
            }
            ion-list {
                background-color: transparent !important;
                ion-item {
                    --background: transparent;
                    background-color: transparent;
                    padding-left: 30px;
                    padding-right: 30px;
                    .input-wrapper {
                        flex-direction: column;
                    }
                    ion-label {
                        max-width: initial;
                        width: 100%;
                        margin: 13px 0 0;
                        white-space: initial;
                        
                        p, pre {
                            font-size: 18px;
                            color: $gris-clair;
                        }
                    }
                    h3 {
                        font-size: 16px;
                        text-transform: uppercase;
                        color: $noir2;
                        margin-bottom: 10px;

                        .red {
                            color: $rouge;
                        }
                    }
                    label {
                        color: $noir;
                        font-size: 16px;
                        input[type="radio"] {
                            display: none;
                            &:checked + span:after {
                                display: inline-block;
                            }
                        }
                        span {
                            &:before {
                                content: "";
                                display: inline-block;
                                vertical-align: middle;
                                margin-right: 10px;
                                border: 1px solid $gris-clair2;
                                border-radius: 50%;
                                width: 30px;
                                height: 30px;
                            }
                            &:after {
                                content: "";
                                display: none;
                                background-image: url("./assets/icon/socotec-sprite-icones.png");
                                background-size: 600%;
                                background-position: -39px -126px;
                                vertical-align: middle;
                                height: 30px;
                                width: 30px;
                                position: absolute;
                                top: 14px;
                                left: 6px;
                            }
                        }
                    }
                        
                    
                    ion-input, ion-datetime {
                        font-size: 16px;
                        line-height: 20px;
                        padding: 12px;
                        border: 1px solid $gris-clair2;
                        border-radius: 20px;
                        width: calc(100% - (16px / 2) - 16px);
                        height: 42px;
                        color: $noir !important;
                    }
                    textarea {
                        font-size: 16px;
                        padding: 12px;
                        border: 1px solid $gris-clair2;
                        width: 100%;
                        height: 125px;
                        color: $noir;
                        background-color: transparent;

                    }
                    &.champs {
                        min-height: initial;
                    }
                    p.red {
                        font-size: 12px;
                        color: $rouge;
                    }
                    figure {
                        margin: 0 0 10px;
                        min-height: 100px;
                        position: relative;
                        display: inline-block;

                        .close {
                            background-color: $blanc;
                            line-height: 20px;
                            text-align: center;
                            text-decoration: none;
                            border-radius: 50%;
                            height: 42px;
                            width: 42px;
                            position: absolute;
                            bottom: 5px;
                            right: 5px;
                        }
                        img {
                            display: inline-block;
                            vertical-align: top;
                        }
                    }
                }
                ion-item.button {
                    cursor: pointer;
                    background-color: $bleu4;
                    padding-left: 0px;
                    margin: 20px 0;
                    p {
                        font-size: 20px;
                        color: $blanc;
                        text-align: center;
                        width: 100%;

                        i.icon {
                            margin-right: 10px;
                        }
                    }
                }
            }
            ion-segment {
                ion-segment-button {
                    font-size: 18px;
                    line-height: 60px;
                    text-transform: initial;
                    color: $gris-clair;
                    height: 60px;
                    border-top: 1px solid rgba($noir2, 0.1);
                    border-left: none;
                    border-right: none;
                    border-bottom: 1px solid rgba($noir2, 0.1);
                    + ion-segment-button {
                        color: $noir2;
                        border-left: 1px solid rgba($noir2, 0.1);
                    }
                    &.segment-button-disabled {
                        opacity: 0.5;
                    }
                }
            }
        }
    }
}


// WEB
.page-web {
    // HOME
    page-home {
        @media (min-width: 768px) {
            background-position: 90% 8%;
            background-size: 40%;
        }
        ion-header, ion-footer {
            @media (min-width: 768px) {
                display: none;
            }
            button.qr-code {
                @media (max-width: 767px) {
                    display: none;
                }
            }
        }
        ion-footer {
            ion-list.spinner {
                ion-spinner.spinner {
                    padding-top: 0px !important;
                }
            }
            ion-list.list {
                height: 106px;
                
                ion-item.qr-code {
                    display: none;
                }
            }
        }
        ion-content {
                main {
                    @media (min-width: 768px) {
                        top: 10%;
                        left: 20%;
                        bottom: auto;
                        width: 40%;
                        background: url("./assets/images/logo.png") top center no-repeat;
                        background-size: 200px;
                        padding-top: 225px;
                    }
                    p.desktop {
                        @media (min-width: 768px) {
                            display: block;
                        }
                    }
                    nav {
                        display: none;
                        
                        @media (min-width: 768px) {
                            display: block;
                            margin-top: 25px;
                        }
                        ion-item {
                            --background: none;
                            --border-color: transparent;
                            ion-label {
                                text-align: center;
                            }
                            @media (min-width: 768px) {
                                display: block;
                                background-color: transparent;
                            }
                            a {
                                @media (min-width: 768px) {
                                    display: inline-block;
                                    height: 58px;
                                    width: 168px;
                                    overflow: hidden;
                                    background: url("./assets/images/icones-app.png") no-repeat;
                                    background-size: 200%;
                                    text-indent: -3000%;
                                }
                                @media (min-width: 768px) and (max-width:991px) {
                                    height: 46px;
                                    width: 120px;
                                } 
                                &.app-store {
                                    @media (min-width: 768px) {
                                        background-position: 0 0;
                                    }
                                }
                                &.google-play {
                                    @media (min-width: 768px) {
                                        background-position: 100% 0;
                                    }
                                }
                            }
                        }
                    }
                
            }
        }
    }

    // MENU
    ion-menu {
        --width: 375px;
        
        @media (max-width: 400px) {
            --width: 300px;
        }
        ion-content {
            overflow: auto;
            
            ion-list {
                ion-item {
                    &.qr-code {
                        display: none;
                    }
                }
            }
            nav {
                ion-item {
                    display: block;
                    background-color: transparent;
                    --background: transparent;

                    .item-inner {
                        border-bottom: none;
                        text-align: center;
                    }
                    a {
                        display: inline-block;
                        height: 58px;
                        width: 168px;
                        overflow: hidden;
                        background: url("./assets/images/icones-app.png") no-repeat;
                        background-size: 200%;
                        text-indent: -3000%;
                        
                        @media (max-width: 400px) {
                            height: 46px;
                            width: 120px;
                        } 
                        &.app-store {
                            background-position: 0 0;
                        }
                        &.google-play {
                            background-position: 100% 0;
                        }
                    }
                }
            }
        }
    }

    // RAPPORT SANS ACCES
    page-rapport-sans-acces {
        component-header {
            button[menutoggle] {
                display: none;
            }
            .title {
                margin-left: 20px;
            }
        }
        .content {
            nav {
                display: block;
            }
            .list {
                .item:first-child, .item:last-child {
                    display: none;
                }
            }
        }
    }
}